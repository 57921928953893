.heading{
color:#F58424;
text-align: center;
text-transform: capitalize;
text-shadow: gray;
font-family: 'Neonderthaw', cursive;
font-size: 4rem;
}

.heading_menu{
    color:red;
    text-align: center;
    text-transform: capitalize;
    text-shadow: gray;
    font-family: 'Marck Script', cursive;
    font-size: 2.5rem;
    }

.heading_menu1{
        color:green;
        text-align: center;
        text-transform: capitalize;
        text-shadow: gray;
        font-family: 'Marck Script', cursive;
        font-size: 2.5rem;
        }
    



.img_nav {
    position: absolute;
    top: 90px;
    z-index: -5;
    animation: rotation 10s infinite linear;
    
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .header-left-side {
      background-color: rgb(246,246,244);
      top: 0px;
     z-index: -10;
     margin-top: -121px;
     padding-top: 200px;

  }

  .header-left1-side {
    background-color: rgb(246,246,244);
    margin-top: -10px;
   
 

}

  .header-right-side {
    background-color: white;
    top: 0px;
   z-index: -10;
   padding-top: 50px;

}

.header-right1-side {
    background-color: white;

}

.hpading {
   margin-right: 5rem;
   margin-left: 5rem;
   margin-top: -38px;
   align-items: center;

}

.btn-outline-primary {
 color: gray;
 border-color: gray;
}

.btn-primary {
    color: white;
    border-color: gray;
    background-color: gray;
   }

.box_del{
    background-color: rgb(246,246,244);
    margin: 2rem; 
    padding: auto;
   
   
}

.box_del1{
    width: 30px;
    
}

.shadow {
    box-shadow: grey;
    border-radius: 15px;
   margin: 12px;
   width: 367px;
}
.shadow:hover{
    transform: translate(5px, -10px);
    background-color: #fdc92c18;
}


.JCC{
   
    padding-top: 20px;
}
.JCC1{
    padding-left: 10px;
}
.JCC2 {
    padding: 20px;
   
}

.margin_partner{
    margin-left: 21px;
}



.line{
    width: 100%;
    height: 45px;
    position: relative;
    padding: 20px;
    
}

.line::after {
content: '';
position:absolute;
margin: auto;
height: 2px;
background-color: rgb(199,199,199);
width: 80%;
top:45;
}

.line::after{
    left: 10;
}

/*--------------------------------------*/
.line1{
    width: 75%;
    height: 45px;
    position: relative;
    padding: 20px;
    
}

.line1::after {
content: '';
position:absolute;
margin: auto;
height: 2px;
background-color: rgb(199,199,199);
width: 80%;
top:45;
}

.line1::after{
    left : 10;
}

/*--------------------------------------*/

.text_bold{
 font-size: 50px;
 padding: 20px;
}

.bg_footer{
    background-color: rgb(199,199,199);
}

.mid_padding {
padding: 50px;
}

.btn-clr {
    color: grey;
    background-color: #fff;
    border-color: gray; /*set the color you want here*/
}
.btn-clr:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #F58424;
    border-color: #285e8e; /*set the color you want here*/
}

.menu_padding {
    padding: 20px;
    justify-content: center;
    align-items: center;
    }


    .square_menu {
        position: relative;
        width: 300px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Iteminside {
    color: #000;
    border: chartreuse;
    border-radius:15 ;
    }

    .Iteminside:after, .Iteminside::before {
        border: 3px solid #000;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        transition: transform 0.3s ease;
    }

    .Iteminside:hover::before{
        transform: translate(5px, -5px);
    }

    .img-div {
        align-items: center;
    }

    

